import React from "react";
import styled from "styled-components";
import { Button, StrictButtonProps } from "semantic-ui-react";

export const MainHeader = styled.div`
  margin-bottom: 5em;
  .header{
    color: #FAB60B;
    text-align: center;
    &.toolkitHeader{
      font-size: 4em;
    }
    &.h0 {
      font-size: 4em;
    }
    &.h1{
      font-size: 3em;
    }
    &.h2{
      font-size: 2.5em;
    }
    &.h3{
      font-size:1.8em;
    }
    &.h4{
      font-size:1.2em;
    }
    a {
      color: #FAB60B;
      &:hover{
        text-decoration: underline;
      }
    }
    /*  */
    &.normal{
      font-weight: normal;
    }
    &.dark{
      color: #000000;
      a {
        color: #000000;
      }
    }
    &.compact{
      margin: 0px;
      line-height: 1.1em
    }
    &.extraSpace{
      margin: 1.2em 0px;
    }
  }

  @media(min-width: 768px){
    .header{
      &.toolkitHeader{
        font-size: 8em;
      }
      &.h0 {
        font-size: 8em;
      }
      &.h1{
        font-size: 5em;
      }
      &.h2{
        font-size:3em;
      }
      &.h3{
        font-size:2.5em;
      }
      &.h4{
        font-size:1.8em;
      }
    }
  }
`;


export const StatementSection = styled.div`
  margin-top: 2.5em;
  margin-bottom: 5em;
  .statement{
    font-size: 1.8em;
    line-height: 1.5em;
    font-weight: bold;
    color: #FAB60B;
  }

  @media(min-width: 768px){
    margin-top:5em;
    margin-bottom: 10em;
    .statement{
      font-size: 3em;
    }
  }
`;

export const FullStorySection = styled.div`
  margin-top: 5em;
  margin-bottom: 10em;

  .column {
    padding-bottom: 2em;
  }
  
  .header{
    &.title{
      font-size: 1.8em;
    } 
  }
  .initiatives{
    margin-top: 2em;
    .header{
      color: #FAB60B;
    }
    p {
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }

  @media(min-width: 768px){
    .header{
      &.title{
        font-size: 3em;
      } 
    }
    .initiatives{
      .header{
        min-height: 100px;
      }
    }
  }

`;

interface IFullStoryButton extends StrictButtonProps{
  customcolor?: "yellow" | "black" | "white";
}

export const FulStoryButton = styled(Button)<IFullStoryButton>`
  &.ui.button{
    background-color: ${props => {
      switch (props.customcolor) {
        case "black":
          return "#000";
        case "white":
          return "#fff";
        case "yellow":
          return "#FAB60B";
        default:
          return "#FAB60B";
      }
    }};
    color: ${props => {
      switch (props.customcolor) {
        case "white":
          return "#FAB60B";
        case "yellow":
          return "#fff";
        case "black":
          return "#fff";
        default:
          return "#fff";
      }
    }};
  }
`;

