import React from "react";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";
import { MainHeader } from "../helpers/styledComponents";

const PageContainer = styled(Container)`
  .privacy {
    padding: 20px;
    text-align: center;
  }

  .privacy a {
    color: black;
    text-decoration: none;
    padding: 0 10px;
  }
  
  .privacy a:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.footer`
  margin-top: 5em;
`

export default () => {
  return (
    <Footer>      
      <PageContainer>
          <MainHeader>
            <Header className="h4 dark">
              For more information, please contact
            </Header>
            <Header className="h4 compact">
              <a href="mailto:info@fullstoryinitiative.com">info@fullstoryinitiative.com</a>
            </Header>
            <div className="privacy">
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms of Use</a>
            </div>
          </MainHeader>

        </PageContainer>
    </Footer>
  )
}

