import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const ModalContainer = styled.div<{open: boolean}>`
  background-color: #FAB60B;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 15px;
  /* centering */
  width: 100%;
  display: ${props => props.open ? "flex": "none"};
  justify-content: center;
  align-items: center;
  .container{
    /* nothing yet */
  }
  .closeIcon{
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
    cursor: pointer;
  }
`;

interface IProps {
  open: boolean,
  onClose: ()=>void;
  children?: any
}

/**
 * Custom Modal that matches styling of the website
 */
export default ({open, onClose, children}: IProps)=>{
  return(
    <ModalContainer open={open}>
      <Icon name="close" className="closeIcon" size="huge" onClick={onClose}/>
      <div className="container">
        {children}
      </div>
    </ModalContainer>
  )
}
