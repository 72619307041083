import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import ColorLogo from '../../static/images/FullStoryInitiative_Logo.png';
import { IntercomProvider } from "react-use-intercom";

const INTERCOM_APP_ID = "zioz5uom";

export interface IUser {
  name: string;
  company: string,
  email: string,
  position: string,
  reason: string
  optin: string
}

const initialState: IUser = {
  name: "",
  email: "",
  company: "",
  position: "",
  reason: "",
  optin: ""
};

const StateContext = createContext(initialState);
const DispatchContext = createContext<any>(undefined);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(initialState);
  useEffect(() => {
    setUser({
      name: localStorage.getItem("fsi_name") || "",
      email: localStorage.getItem("fsi_email") || "",
      company: localStorage.getItem("fsi_company") || "",
      position: localStorage.getItem("fsi_position") || "",
      reason: localStorage.getItem("fsi_reason") || "",
      optin: localStorage.getItem("fsi_optin") || ""
    });
  },[ setUser]);

  return (
    <StateContext.Provider value={user}>
      <DispatchContext.Provider value={setUser}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useUserState = () => {
  return useContext(StateContext);
};

export const useUserActions = () => {
  const setUser = useContext(DispatchContext);

  const saveUser = useCallback(
    (data: IUser) => {
      localStorage.setItem("fsi_name", data.name || '');
      localStorage.setItem("fsi_email", data.email || '');
      localStorage.setItem("fsi_company", data.company || '');
      localStorage.setItem("fsi_position", data.position || '');
      localStorage.setItem("fsi_optin", data.optin || '');
      if(data.reason) {
        localStorage.setItem(`fsi_${data.reason}`, data.reason);
      }
      setUser(data);
    },
    [ setUser ]
  );
  return useMemo(() => {
    return {
      saveUser
    }
  },
  [ saveUser ])
}

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body{
    height: auto;
    min-height: 100%;
  }
  body {
    background-color: #FFF9E9;
    font-family: "Mont", Helvetica,Arial,sans-serif !important;

    h1, h2, h3, h4, h5, h6, b, strong{
      font-family: "Mont", Helvetica, Arial, sans-serif !important;
      font-weight: bold;
    }
    .ui.header {
      font-family: "Mont", Helvetica, Arial, sans-serif !important;
    }

    a {
      color: #FAB60B;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  #intercom-container{
    display: none;
  }
  .intercom-lightweight-app {
    display: none;
  }
`;

const ViewPort = styled.div`
`;


interface IProps {
  children?: any
};
declare global {
  interface Window {
    OneTrust: any,
    Intercom: any
  }
}

const Layout: React.FC<IProps> = ({
  children
}) => {

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ViewPort>
        <GlobalStyles />
        <Helmet>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script="bd108407-9b07-4e45-837f-ed8ed6e7d16b"
          />
          <meta charSet="utf-8" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1 user-scalable=no"
          />
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="Full Story Initiative" />
          <link rel="apple-touch-icon" sizes="57x57" href="/app-icons/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/app-icons/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/app-icons/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/app-icons/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/app-icons/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/app-icons/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/app-icons/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/app-icons/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/app-icons/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192"  href="/app-icons/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/app-icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/app-icons/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/app-icons/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="msapplication-TileColor" content="#fff939" />
          <meta name="msapplication-TileImage" content="/app-icons/ms-icon-144x144.png" />
          <meta name="author" content="Full Story Initiative"/>
          <meta property="og:image" content={ColorLogo}/>
          <meta property="og:url" content="" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Full Story Initiative"/>
          <meta name="twitter:card" content={ColorLogo}/>
          <meta name="twitter:image" content="/app-icons/apple-icon-72x72.png" />
          <meta name="twitter:image:alt" content="Full Story Initiative"/>
          <meta
            name="description"
            content="The Full Story Initiative provides the infrastructure needed from within the entertainment industry to encourage more accurate portrayals of complex social issues and historically underrepresented communities in television and film."
          />
          <meta
            name="og:description"
            content="The Full Story Initiative provides the infrastructure needed from within the entertainment industry to encourage more accurate portrayals of complex social issues and historically underrepresented communities in television and film."
          />
          <meta
            name="twitter:description"
            content="The Full Story Initiative provides the infrastructure needed from within the entertainment industry to encourage more accurate portrayals of complex social issues and historically underrepresented communities in television and film."
          />
          <html lang="en" />
          <script 
            src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js" 
            type="text/javascript" 
            charSet="UTF-8" 
            id="otprivacy-notice-script"
          >
            settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"
          </script>
        </Helmet>
        <Header />
        <UserProvider>
          {children}
        </UserProvider>
        <Footer />
      </ViewPort>
    </IntercomProvider>
  )
};

export default Layout;
