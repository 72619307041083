import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Grid } from "semantic-ui-react";
import ColorLogo from '../../static/images/FullStoryInitiative_Logo.png';
import Modal from "../components/Modal";

const LogoImage = styled.img`
  width: 100%;
  max-width: 220px;
`;

const NavigationBarContainer = styled(Container)`
  padding: 20px 0 40px;
  @media(min-width: 768px){
    padding: 50px 0 80px;
  }
  ul{
    padding-left: 0px;
    list-style-type: none;
  }
  .bullets {
    margin-bottom: 20px;
    cursor: pointer;
    li {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-color: black;
      margin-right: 10px;
      border-radius: 100%;
      &:last-child{
        margin-right: 0px;
      }
      &.b1{
        background-color: #FF8500;
      }
      &.b2{
        background-color: #FB9B08;
      }
      &.b3{
        background-color: #FF4E00;
      }
    }
  }

  ul.menuLinks{
    a {
      padding: 5px 0;
      display: inline-block;
      color: #000;
      line-height: 1.1em;
      &:hover{
        text-decoration: underline;
      }
    }

    &.desktop{
      display: none;
      @media(min-width: 768px){
        display: block;
      }
    }
  }
`;

const MobileMenuContainer = styled.div`
  ul.menuLinks{
    font-size: 3.5em;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    a {
      display: inline-block;
      color: #fff;
    }
    li {
      &:after{
        content: "";
        display: block;
        width: 120px;
        height: 3px;
        background-color: #fff;
        margin: 35px auto;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }
`;

interface IMenuLinksProps{
  className?: string;
  onLinkClick?: ()=>void;
}
const MenuLinks = ({ className, onLinkClick}:IMenuLinksProps)=>(
  <ul className={`menuLinks ${className}`}>
    <li>
      <Link to="/" onClick={onLinkClick} id="OurStory">
        OUR STORY
      </Link>
    </li>
    <li>
      <Link to="/advisoryCouncil" onClick={onLinkClick} id="AdvisoryCouncil">
        ADVISORY COUNCIL
      </Link>
    </li>
    <li>
      <Link to="/initiativeToolkit" onClick={onLinkClick} id="Toolkit">
        INITIATIVE TOOLKIT
      </Link>
    </li>
    <li>
      <Link to="/research" onClick={onLinkClick} id="Research">
        RESEARCH
      </Link>
    </li>
  </ul>
);

const NavigationBar = ()=>{
  const [openMenuModal, setOpenMenuModal] = useState(false);
  return(
    <NavigationBarContainer>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Link to="/">
              <LogoImage src={ColorLogo} alt="Full Story Initiative Logo" />
            </Link>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <ul className="bullets" onClick={()=> setOpenMenuModal(true)}>
              <li className="b1"></li>
              <li className="b2"></li>
              <li className="b3"></li>
            </ul>
            <MenuLinks className="desktop" onLinkClick={()=> setOpenMenuModal(false)} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal open={openMenuModal} onClose={()=> setOpenMenuModal(false)}>
        <MobileMenuContainer>
          <MenuLinks onLinkClick={()=> setOpenMenuModal(false)}  />
        </MobileMenuContainer>
      </Modal>
    </NavigationBarContainer>
  )
}

export default NavigationBar